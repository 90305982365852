<template>
  <div class="todo">
    <div class="content">
      <ModTit>
        <template v-slot:left>
          <h3>
            {{
              module.menuJsonObj
                ? module.menuJsonObj[langNamesEnum[languageActive]]
                : module.title
            }}（{{ dataSource.length }}{{ $t("Home.pcs")
            }}<!-- 个 -->）
            <span class="tips"
              >{{ $t("Home.todoTips")
              }}<!-- 即将开始/进行中的考试、面授和直播 --></span
            >
          </h3>
        </template>
        <template v-slot:right>
          <div class="ctrl" v-if="todoList.length > 1">
            <LeftOutlined class="icon" @click="currentChange(-1)" />
            {{ currentIndex + 1 }}
            /
            {{ todoList.length }}
            <RightOutlined class="icon" @click="currentChange(1)" />
          </div>
        </template>
      </ModTit>
      <div class="card">
        <div
          class="item"
          v-for="(todo, i) in todoList[currentIndex]"
          :key="i"
          @click="jump(todo)"
        >
          <div class="top">
            <div class="type">
              <template v-if="todo.taskType == 2">
                {{ $t("daily_exam") }}
              </template>
              <template v-else>
                {{ getDetailType(todo.resourceType) }}
              </template>
            </div>
            <div class="status green" v-if="todo.isStart">
              {{ $t("Home.inProgress") }}
            </div>
            <div class="status orange" v-else>
              {{ $t("Home.beginInAMinute") }}
            </div>
          </div>
          <div class="bottom">
            <div class="cover">
              <img
                :src="
                  require('@/assets/image/home/type-' +
                    (todo.taskType == 2 ? '2' : todo.resourceType) +
                    '.png')
                "
                alt=""
              />
            </div>
            <div class="info">
              <div class="title">
                <template v-if="todo.resourceType == 3">
                  {{ todo.reexamId ? todo.taskName : todo.detailName }}
                </template>
                <template v-else>
                  {{
                    todo.resourceType == 33 ? todo.taskName : todo.detailName
                  }}
                </template>
              </div>
              <div class="time">
                {{ dateFormat(todo.startTime) }}-{{ dateFormat(todo.endTime) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { dateFormat } from "@/utils/tools";
import { getDetailType } from "@/utils/business";
import { projectDetail } from "@/api/project";
import ModTit from "@/components/layout/ModTit.vue";
export default {
  name: "todo",
  components: {
    ModTit,
  },
  props: {
    module: {
      type: Object,
      default: () => {
        return {};
      },
    },
    langNamesEnum: {
      type: Object,
      default: () => {
        return {};
      },
    },
    languageActive: {
      type: String,
      default: "",
    },
    dataSource: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    // 数组 一维转二维
    const arrTrans = (arr, num) => {
      const newArr = [];
      const total = Math.ceil(arr.length / num);
      for (let i = 0; i < total; i++) {
        const temp = arr.slice(i * num, (i + 1) * num);
        newArr.push(temp);
      }
      return newArr;
    };
    const todoList = ref(props.dataSource);
    const currentIndex = ref(0);
    const initData = () => {
      todoList.value = arrTrans(props.dataSource, 3);
      const nowS = Date.parse(new Date()) / 1000;
      todoList.value.forEach((data) => {
        data.map((item) => {
          // 根据时间差 看是否开始
          item.isStart = item.startTime - nowS > 0 ? false : true;
          const { d, h, m, s } = formatDate(
            item.isStart ? item.endTime : item.startTime
          );
          item.d = d;
          item.h = h;
          item.m = m;
          item.s = s;
        });
      });
    };
    // 时间处理
    const formatDate = (endTime) => {
      let stime = new Date().getTime() / 1000;
      let etime = new Date(endTime).getTime();
      let intervalTime = etime - stime;
      const d = Math.floor(intervalTime / 60 / 60 / 24);
      const h = Math.floor((intervalTime / 60 / 60) % 24);
      const m = Math.floor((intervalTime / 60) % 60);
      const s = Math.floor(intervalTime % 60);
      const res = {
        d: d < 10 ? "0" + d : d,
        h: h < 10 ? "0" + h : h,
        m: m < 10 ? "0" + m : m,
        s: s < 10 ? "0" + s : s,
      };
      return res;
    };
    const jump = async (item) => {
      await getResourceLearn(item);
      if (item.resourceType !== 33) {
        if ([4, 7].includes(item.resourceType)) {
          if (!item.isStart) return proxy.$message.error("问卷暂不能学习");
        }
        if (!item.detailLearn) {
          proxy.$message.error("请先完成前面的资源");
          router.push({
            path: "/project/detail",
            query: {
              id: item.taskId,
            },
          });
          return false;
        }
      }
      switch (item.resourceType) {
        case 3:
          if (item.reexamId) {
            router.push({
              path: "/exam/detail",
              query: {
                id: item.taskId,
                did: item.detailId,
                reexamId: item.reexamId,
                taskType: 23,
              },
            });
          } else {
            router.push({
              path: "/exam/detail",
              query: {
                id: item.taskId,
                did: item.detailId,
              },
            });
          }
          break;
        case 4:
          router.push({
            path: "/questionnaire/detail",
            query: {
              source: "project",
              id: item.taskId,
              did: item.detailId,
              resourceId: item.resourceId,
              progress: item.progress,
            },
          });
          break;
        case 7:
          router.push({
            path: "/questionnaire/detail",
            query: {
              source: "project",
              id: item.taskId,
              did: item.detailId,
              resourceId: item.resourceId,
              progress: item.progress,
            },
          });
          break;
        case 8:
          router.push({
            path: "/train/detail",
            query: {
              id: item.taskId,
              did: item.detailId,
            },
          });
          break;
        case 9:
          router.push({
            path: "/live/detail",
            query: {
              id: item.taskId,
              did: item.detailId,
            },
          });
          break;
        case 33:
          if (!item.isStart) {
            return proxy.$message.error("活动暂未开始");
          }
          router.push({
            path: "/questionnaire/detail",
            query: {
              source: "activity",
              id: item.taskId,
              did: item.detailId,
              resourceId: item.resourceId,
              progress: item.progress,
            },
          });
          break;
      }
    };
    initData();
    // 资源是否可学
    const getResourceLearn = async (resource) => {
      const result = await projectDetail(resource.taskId);
      if (result.ret !== 0) return false;
      let data = result.data,
        nowTime = new Date().getTime();
      data.remainderDay = Math.floor(
        (data.endTime * 1000 - nowTime) / 86400000
      );
      data.remainderHours = Math.floor(
        (data.endTime * 1000 - nowTime) / 3600000
      );
      data.stages.forEach((s, sI) => {
        //项目未逾期
        if (data.complete != 3 && data.remainderDay >= 0) {
          // 阶段是否可学
          if (data.learnOrder == 2) {
            s.learnFlag = true;
          } else {
            if (sI == 0) {
              s.learnFlag = true;
            } else {
              data.stages[sI - 1].progress == 100 && (s.learnFlag = true);
            }
          }
          s.details.forEach((d, dI) => {
            //阶段明细是否可学
            if (s.learnFlag) {
              if (s.learnOrder == 2) {
                d.learnFlag = true;
              } else {
                if (dI == 0) {
                  d.learnFlag = true;
                } else {
                  s.details[dI - 1].progress == 100 && (d.learnFlag = true);
                }
              }
            }
            resource.detailId == d.detailId &&
              (resource.detailLearn = d.learnFlag);
          });
        } else {
          //阶段是否可学
          if (s.progress == 100) {
            s.learnFlag = true;
          } else {
            if (sI == 0) {
              s.learnFlag = true;
            }
          }
          s.details.forEach((d, dI) => {
            //阶段明细是否可学
            if (d.progress == 100) {
              d.learnFlag = true;
            } else {
              if (dI == 0) {
                d.learnFlag = true;
              } else {
                s.details[dI - 1].progress == 100 && (d.learnFlag = true);
              }
            }
            resource.detailId == d.detailId &&
              (resource.detailLearn = d.learnFlag);
          });
        }
        resource.stageId == s.stageId && (resource.stagesLearn = s.learnFlag);
      });
    };

    const currentChange = (type) => {
      if (type == 1) {
        if (currentIndex.value + 1 < todoList.value.length) {
          currentIndex.value++;
        }
      } else {
        if (currentIndex.value + 1 > 1) {
          currentIndex.value--;
        }
      }
    };
    return {
      todoList,
      dateFormat,
      getDetailType,
      currentIndex,
      currentChange,
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
.todo {
  .content {
    .mixinWrap();
    .mod-tit {
      h3 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 34px;
        .tips {
          padding-left: 4px;
          font-size: 14px;
          color: #666;
          font-weight: 400;
        }
      }
      .ctrl {
        .mixinFlex(flex-start; center);
        font-size: 14px;
        color: #666;
        .icon {
          font-size: 12px;
          margin: 0 6px;
          cursor: pointer;
          color: #333;
        }
      }
    }
    .card {
      .mixinFlex(flex-start; center);
      .item {
        width: 387px;
        border-radius: 8px;
        background: #f6faff;
        padding: 16px;
        margin-right: 20px;
        cursor: pointer;
        transition: all 0.3s ease;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          transform: translateY(-4px);
          box-shadow: 0 6px 20px rgba(148, 148, 148, 0.15);
          .bottom {
            .info {
              .title {
                color: @color-theme;
              }
            }
          }
        }
        .top {
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
          .mixinFlex(space-between; center);
          .type {
            color: #333;
          }
          .status {
            &.green {
              color: #44b44f;
            }
            &.orange {
              color: #e8993e;
            }
          }
        }
        .bottom {
          margin-top: 16px;
          .mixinFlex(space-between; center);
          .cover {
            .mixinImgWrap(60px; 60px);
          }
          .info {
            width: calc(100% - 76px);
            .title {
              color: #333;
              font-size: 16px;
              font-weight: 600;
              .mixinEllipsis(26px);
            }
            .time {
              color: #999;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px;
            }
          }
        }
      }
    }
  }
}
</style>
